@import '../node_modules/@de.fiduciagad.kundenportal/kf-theme/index.scss';

@import 'bootstrap-icons/font/bootstrap-icons.scss';
/* @import 'bootstrap-icons/font/bootstrap-icons.scss';
@import '../node_modules/@de.fiduciagad.kundenportal/kf-theme/styles/bundle.scss'; */
/* @import '../node_modules/@de.fiduciagad.kundenportal/kf-theme/styles/fonts/_kf-icons.scss'; */

:root {
  --kf-bg-primary: var(--color-background-stage, #005a71);
  --kf-bg-accent: var(--color-accent-500, #ff6600);
  --kf-bg: var(--color-background-background, #ffffff);

  --kf-color: var(--color-foreground-text, #282828);
  --kf-color-contrast: var(--color-background-background, #f7f7f7);
  --kf-color-primary: var(--color-primary-500, #2766ad);
  --kf-color-warn: var(--color-warn, #ff0000);
}
